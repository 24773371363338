import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { RequestModificationDialogComponent } from '@app/shared/components/request-modification-dialog/request-modification-dialog.component';
import { NotificationService } from '@app/shared/services';
import { NavbarService } from '@app/user-dashboard/components/navbar/navbar.service';
import { TranslateService } from '@ngx-translate/core';
import { CONFIGURATION } from 'configuration/configuration';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_AVATAR } from '../../../user-management/consts/default-avatar.const';
import { UserService } from '../../../user-management/services/user.service';
import { CategoriesService } from '../../services/categories.service';
import { ScoresService } from '../../services/scores.service';
import { VoteService } from '../../services/vote.service';
import { getUserImage } from '@app/shared/utils/image.utils';
import { environment } from '@env/environment';
import {
    NominatorAiFeedbackDialogComponent
} from "@app/shared/components/nominator-ai-feedback-dialog/nominator-ai-feedback-dialog.component";

@Component({
    selector: 'app-nomination-vote',
    templateUrl: 'nomination-vote.component.view.html',
    styleUrls: ['nomination-vote.component.view.scss']
})
export class NominationVoteComponent implements OnInit, OnDestroy {

    private routeChanges: Subscription;
    private nominationId: number;

    public nomination: any;
    public score: any;
    public categories: any = [];
    public categoryTypes: any = [];

    public readonly displayedColumns = ['voter', 'score', 'description', 'created'];
    public votes = new MatTableDataSource<any>();
    public votesVisible = false;

    public readonly fibos = [0, 1, 2, 3, 5, 8, 13, 21];
    public readonly MIN_VOTE_SCORE = 0;
    public readonly DEFAULT_VOTE_SCORE = -1;
    public voteScore = this.DEFAULT_VOTE_SCORE;
    public voteDescription = '';
    public nominationGifURL = '';

    public readonly DESCRIPTION_MAX_LENGTH = CONFIGURATION.nomination.description.length.max;
    public readonly MODERATOR_COMMENT_MAX_LENGTH = CONFIGURATION.nomination.moderatorComment.length.max;

    public relatedSelectorOpen = false;

    @ViewChild('navbarTemplate', { static: true })
    navbarTemplate: TemplateRef<any>;

    defaultVoteDescriptions: string[] = new Array<string>();
    defaultModeratorComments: string[] = new Array<string>();
    nominationForm: FormGroup;
    userPicture = DEFAULT_AVATAR;
    DEFAULT_AVATAR = DEFAULT_AVATAR;
    chatModuleEnabled = environment.chatModuleConfig.enabled;

    constructor(
        private activatedRoute: ActivatedRoute,
        private nominationService: NominationService,
        private scoreService: ScoresService,
        private categoryService: CategoriesService,
        private voteService: VoteService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private navbarService: NavbarService,
        private userService: UserService,
        private formBuilder: FormBuilder,
        private notification: NotificationService) {
        this.initDefaultDescriptions();
        this.createForm();
        this.routeChanges = this.activatedRoute.params.pipe(map(p => p.id)).subscribe(id => {
            this.nominationId = id;
            this.loadCategories();
            this.loadNomination();
            this.loadVotes();
        });
    }

    ngOnInit() {
        this.navbarService.updateTemplate(this.navbarTemplate);
    }

    ngOnDestroy() {
        this.routeChanges.unsubscribe();
    }

    private initDefaultDescriptions() {
        this.defaultModeratorComments.push(this.translate.instant('nominations.moderatorComments.tooGenericMakeItSpecific'));
        this.defaultModeratorComments.push(this.translate.instant('nominations.moderatorComments.alreadyNominated'));
        this.defaultModeratorComments.push(this.translate.instant('nominations.moderatorComments.thankYou'));

        this.defaultVoteDescriptions.push(this.translate.instant('nominations.defaultDescriptions.closedWithoutFurtherNote'));
        this.defaultVoteDescriptions.push(this.translate.instant('nominations.defaultDescriptions.seeVoteStatus'));
        this.defaultVoteDescriptions.push(this.translate.instant('nominations.defaultDescriptions.abstinence'));
    }

    private createForm() {
        this.nominationForm = this.formBuilder.group({
            nominationCategoryType: [{
                value: '',
                disabled: true
            }, Validators.compose([
                Validators.required
            ])],
            nominationCategoryId: [{
                value: '',
                disabled: !this.isInProgress
            }, Validators.compose([
                Validators.required
            ])],
            nominationDescription: [{
                value: '',
                disabled: !this.isInProgress
            }, Validators.compose([
                Validators.required,
                Validators.maxLength(this.DESCRIPTION_MAX_LENGTH)
            ])],
            moderatorComment: [{
                value: '',
                disabled: !this.isInProgress
            }, Validators.compose([
                Validators.maxLength(this.MODERATOR_COMMENT_MAX_LENGTH)
            ])]
        });
    }

    private async loadCategories() {
        const data = await this.categoryService.getCategories();
        this.categories = data;
        const categoryTypes: any = {};
        this.categories.forEach((categoryType) => {
            categoryTypes[categoryType.categoryType] = categoryTypes[categoryType.categoryType] || {
                name: this.translate.instant(`nominations.categoryTypes.${categoryType.categoryType}`),
                categories: []
            };
            categoryTypes[categoryType.categoryType].categories.push(categoryType);
        });
        const results = [];
        for (const key in categoryTypes) {
            results.push(categoryTypes[key]);
        }
        this.categoryTypes = results;
    }

    private async loadNomination() {
        const data = await this.nominationService.getById(this.nominationId);
        this.nomination = data;

        this.nominationCategoryId = this.nomination.category.id;
        this.nominationCategoryType = this.translate.instant(`nominations.categoryTypes.${this.nomination.category.categoryType}`);
        this.nominationDescription = this.nomination.description;
        this.moderatorComment = this.nomination.moderatorComment;
        this.nominationGifURL = this.nomination.gifUrl;
        this.userPicture = getUserImage(this.nomination.nominatedUser.email);

        this.voteScore = this.nomination.score || this.DEFAULT_VOTE_SCORE;
        this.voteDescription = this.nomination.closeDescription;
        this.loadScore();

        if (this.isInProgress) {
            this.nominationCategoryIdControl.enable();
            this.nominationDescriptionControl.enable();
            this.moderatorCommentControl.enable();
        }
    }

    private async loadVotes() {
        const response = await this.voteService.getAllByNomination(this.nominationId);
        this.votes.data = response.data.votes;
        this.votesVisible = response.data.votesVisible;
    }

    nominationCategoryChange(event: MatSelectChange) {
        const category = this.categories.filter(category => category.id == event.value)[0];
        this.nominationCategoryType = this.translate.instant(`nominations.categoryTypes.${category.categoryType}`);
    }

    private async loadScore() {
        const data = await this.scoreService.getUserScore(this.nomination.nominatedUser.id);
        this.score = data;
    }

    setVoteScore(fibo: number) {
        this.voteScore = fibo;
    }

    async saveVote() {
        await this.voteService.createByNomination(this.nomination.id, {
            description: this.voteDescription,
            score: this.voteScore
        });
        this.notification.success('nominations.notifications.voteSaveSuccessfull',
            { titleKey: 'nominations.notifications.voted' });
        this.loadNomination();
        this.loadVotes();
    }

    requestNominationToModify() {
        const dialogRef = this.dialog.open(RequestModificationDialogComponent, {
            width: '460px',
            data: this.nomination
        });
        dialogRef.afterClosed().subscribe(async (result: any) => {
            if (result) {
                this.nomination.status = 'MODIFY';
            }
        });
    }

    async saveNomination(): Promise<boolean> {
        this.nominationForm.markAllAsTouched();

        if (!this.nominationForm.valid) {
            this.notification.error('nominations.notifications.checkNominationFormContent',
                { titleKey: 'nominations.notifications.correctionRequired' });
            return false;
        }

        await this.nominationService.save(this.nomination.id, {
            description: this.nominationDescription,
            moderatorComment: this.moderatorComment,
            categoryId: this.nominationCategoryId,
            gifUrl: this.nominationGifURL ? this.nominationGifURL : undefined
        });

        this.notification.success('nominations.notifications.nominationSaveSuccessfull',
            { titleKey: 'nominations.notifications.saved' });
        this.loadNomination();
        return true;
    }

    async declineNomination() {
        const nominationSaved = await this.saveNomination();
        if (!nominationSaved) {
            return;
        }
        const updatedNomation = await this.nominationService.decline(this.nomination.id, {
            closeDescription: this.voteDescription,
            score: this.voteScore
        });
        this.notification.success('nominations.notifications.nominationDeclineSuccessfull',
            { titleKey: 'nominations.notifications.declined' });
        this.nomination = updatedNomation;
        this.loadScore();
        this.nominationCategoryIdControl.disable();
        this.nominationDescriptionControl.disable();
        this.moderatorCommentControl.disable();
    }

    async reopenNomination() {
        const updatedNomation = await this.nominationService.reopen(this.nomination.id, {});
        this.notification.success('nominations.notifications.nominationReopenSuccessfull',
            { titleKey: 'nominations.notifications.reopened' });
        this.nomination = updatedNomation;
        this.loadScore();
        this.nominationCategoryIdControl.enable();
        this.nominationDescriptionControl.enable();
        this.moderatorCommentControl.enable();
    }

    async approveNomination() {
        if ((this.nomination.aiScoreSuggestion && this.nomination.aiScoreSuggestion.similarNominations.length < 1) ||
                !this.nomination.aiScoreSuggestion) {
            if (this.requiredAmountOfVoteGiven){
                const nominationSaved = await this.saveNomination();
                if (nominationSaved) {
                    this.approve();
                }
                return;
            }

            const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                width: '350px',
                data: {
                    message: this.translate.instant('nominations.confirmations.approve'),
                    yes: this.translate.instant('nominations.actions.approve'),
                    no: this.translate.instant('buttons.cancel'),
                }
            });
            dialogRef.afterClosed().subscribe(async (result: any) => {
                if (result) {
                    const nominationSaved = await this.saveNomination();
                    if (nominationSaved) {
                        this.approve();
                    }
                }
            });
        } else {
            const dialogRef = this.dialog.open(NominatorAiFeedbackDialogComponent, {
                width: '1000px',
                data: {
                    suggestedScore: this.voteScore,
                    nomination: this.nomination,
                    approveFunction: () => this.approve()
                }
            });
        }
    }

    private async approve() {
        const updatedNomation = await this.nominationService.approve(this.nomination.id, {
            closeDescription: this.voteDescription,
            score: this.voteScore
        });
        this.nomination = updatedNomation;
        this.notification.success('nominations.notifications.nominationApproveSuccessfull',
            { titleKey: 'nominations.notifications.approved' });
        this.loadScore();
    }

    changeRelatedSelector(open: boolean) {
        this.relatedSelectorOpen = open;
    }

    removeGif() {
        this.nominationGifURL = "";
    }

    changeDescription(newVoteDescription: string) {
        this.voteDescription = newVoteDescription;
    }

    changeModeratorComment(newModeratorComment: string) {
        this.moderatorComment = newModeratorComment;
    }

    hasError(field: string, validator: string): boolean {
        return this.nominationForm.get(field).hasError(validator);
    }

    jumpToChat() {
        const chatModul = document.getElementById('chatModul');
        const y = chatModul.getBoundingClientRect().top + window.pageYOffset + (-65);
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    get requiredAmountOfVoteGiven(): boolean {
        return this.nomination.voteStatistics.actVoteCount >= this.nomination.voteStatistics.requiredVoteCunt;
    }

    get isInProgress(): boolean {
        return this.nomination && ['CREATED', 'PENDING', 'MODIFY'].indexOf(this.nomination.status) !== -1;
    }

    get isModificationInProgress(): boolean {
        return this.nomination && ['MODIFY'].indexOf(this.nomination.status) !== -1;
    }

    get isClosed(): boolean {
        return this.nomination && ['APPROVED', 'DECLINED'].indexOf(this.nomination.status) !== -1;
    }

    get voteMode(): boolean {
        return !this.votesVisible && this.isInProgress;
    }

    get finalizedNomination(): boolean {
        return !this.votesVisible && this.isClosed;
    }

    get finalizeMode(): boolean {
        return this.votesVisible;
    }

    get atLeastOneVoteGiven(): boolean {
        return this.nomination.voteStatistics.actVoteCount > 0;
    }

    get nominationCategoryTypeControl(): AbstractControl {
        return this.nominationForm.get('nominationCategoryType');
    }

    get nominationCategoryIdControl(): AbstractControl {
        return this.nominationForm.get('nominationCategoryId');
    }

    get nominationDescriptionControl(): AbstractControl {
        return this.nominationForm.get('nominationDescription');
    }

    get moderatorCommentControl(): AbstractControl {
        return this.nominationForm.get('moderatorComment');
    }

    get nominationCategoryType(): string {
        return this.nominationCategoryTypeControl.value;
    }

    set nominationCategoryType(newValue: string) {
        this.nominationCategoryTypeControl.setValue(newValue);
    }

    get nominationCategoryId(): string {
        return this.nominationCategoryIdControl.value;
    }

    set nominationCategoryId(newValue: string) {
        this.nominationCategoryIdControl.setValue(newValue);
    }

    get nominationDescription(): string {
        return this.nominationDescriptionControl.value;
    }

    set nominationDescription(newValue: string) {
        this.nominationDescriptionControl.setValue(newValue);
    }

    get moderatorComment(): string {
        return this.moderatorCommentControl.value;
    }

    set moderatorComment(newValue: string) {
        this.moderatorCommentControl.setValue(newValue);
    }

    get scoreSelected(): boolean {
        return this.voteScore >= this.MIN_VOTE_SCORE;
    }

    get votingAllowedInCurrentStatus(): boolean {
        return ['MODIFY', 'DECLINED', 'APPROVED'].indexOf(this.nomination.status) === -1;
    }

    get voteCanBeSent() {
        return this.scoreSelected && this.votingAllowedInCurrentStatus;
    }

    get saveAllowedInCurrentStatus(): boolean {
        return this.votingAllowedInCurrentStatus;
    }

}
