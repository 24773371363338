import {Component, Inject, Input} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NominationService } from '@app/nomination-management/services/nomination.service';
import {NominatorAiService} from '@app/shared/components/nominator-ai-feedback-dialog/service/nominator-ai.service';
import {NotificationService} from "@app/shared/services";

@Component({
    selector: 'app-nominator-ai-feedback-dialog',
    templateUrl: './nominator-ai-feedback-dialog.component.html',
    styleUrls: ['./nominator-ai-feedback-dialog.component.scss']
})
export class NominatorAiFeedbackDialogComponent {
    public moderatorComment: string;
    public nomination: any;
    public suggestedScore: number;

    public constructor(
        private aiService: NominatorAiService,
        public dialogRef: MatDialogRef<NominatorAiFeedbackDialogComponent>,
        private readonly notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        this.nomination = data.nomination;
        this.suggestedScore = data.suggestedScore;
    }

    public async onButtonClick(): Promise<void> {
        try {

            if (this.requiredAmountOfFeedbackGiven < this.nomination.voteStatistics.requiredFeedbackCount) {
                this.notification.error('nominations.notifications.ai.notEnoughFeedback',
                    { titleKey: 'nominations.notifications.ai.notEnoughFeedbackTitle' });
                return;
            }

            await this.data.approveFunction();

            // Prepare the feedback data
            const feedbacks = [];
            const similarNominations = this.nomination.aiScoreSuggestion.similarNominations;

            similarNominations.forEach(element => {
                feedbacks.push({
                    old_nomination_id: element.id,
                    similarity: element.feedback
                });
            });

            this.aiService.sendFeedback({
                nominationId: this.nomination.id,
                score: this.suggestedScore,
                feedbackDtoList: feedbacks
            }).catch((err) => {
                console.error('Error sending feedback:', err);
            });

            console.log('Feedback submission initiated!');
        } catch (err) {
            console.error('Error during approval:', err);
        } finally {
            // Close the dialog regardless of success or failure
            this.dialogRef.close();
        }
    }

    get requiredAmountOfFeedbackGiven(): number {
        return this.nomination.aiScoreSuggestion.similarNominations
            .filter(nomination => nomination.feedback !== null && nomination.feedback !== undefined).length;
    }


}
