import { Injectable } from '@angular/core';
import {HttpMethod, IbnHttpService} from '@app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class NominatorAiService {

  constructor(private httpService: IbnHttpService) { }


  public async sendFeedback(data: any) {
    return this.httpService.Request(`/nominator-ai/feedback`, HttpMethod.POST, data);
  }
}
