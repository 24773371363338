<div class="mx-3 mb-3">
    <table mat-table [dataSource]="dataSource"
        class="bonus-design">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.ai.tableColumns.id</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <span>#{{model.id}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef >
                <span translate>nominations.ai.tableColumns.description</span>
            </th>
            <td mat-cell *matCellDef="let model" class="w-50">
                {{model.description}}
            </td>
        </ng-container>

        <ng-container matColumnDef="score">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.ai.tableColumns.score</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <span>{{model.score}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="distance">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.ai.tableColumns.distance</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <span>{{model.distance}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="vote">
            <th mat-header-cell *matHeaderCellDef>
                <span translate>nominations.ai.tableColumns.vote</span>
            </th>
            <td mat-cell *matCellDef="let model">
                <app-feedback [similarNomination]="model"></app-feedback>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

</div>
