import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SettingsService, StorageService } from '@app/shared/services';
import { environment } from '@env/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ToastrModule } from 'ngx-toastr';
import { TimeagoModule } from 'ngx-timeago';
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppSpinnerComponent } from './initializr/app-spinner.component';
import { AppInitializrComponent } from './initializr/app.component';
import { NominationClosedListComponent } from './nomination-management/components/list/closed/nomination-closed-list.component';
import { NominationComponent } from './nomination-management/components/list/nomination.component';
import { NominationOpenedListComponent } from './nomination-management/components/list/opened/nomination-opened-list.component';
import { NominationListWrapperComponent } from './nomination-management/components/list/wrapper/nomination-wrapper.component';
import { NominationVoteComponent } from './nomination-management/components/vote/nomination-vote.component';
import { PurchaseComponent } from './purchase-management/add/purchase.component';
import { ConfirmPurchaseDeleteComponent } from './purchase-management/confirm-delete/confirm-delete.component';
import { PurchaseListComponent } from './purchase-management/list/purchase-list.component';
import { RequestModificationDialogComponent } from './shared/components/request-modification-dialog/request-modification-dialog.component';
import { NominationStatusBadgeComponent } from './shared/components/status-badge/nomination-status-badge.component';
import { MaxInputLengthNotifierDirective } from './shared/directives/max-input-length-notifier.directive/max-input-length-notifier.directive';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { UrlInterceptor } from './shared/helpers/url.interceptor';
import { LoginComponent } from './user-auth/components/login/login.component';
import { LogoutComponent } from './user-auth/components/logout/logout.component';
import { DashboardLayoutComponent } from './user-dashboard/components/layout/dashboard-layout.component';
import { NavbarComponent } from './user-dashboard/components/navbar/navbar.component';
import { SidenavComponent } from './user-dashboard/components/sidenav/sidenav.component';
import { UserListComponent } from './user-management/user-list/user-list.component';
import { UserCreateComponent } from './user-management/user/user.component';
import { DisqusModule } from "ngx-disqus";
import { ChatComponent } from './chat/chat-modul/chat.component';
import { ChatService } from './chat/service/chat.service';
import { MessageNumberComponent } from './chat/message-number/message-number.component';
import { TokenLoginComponent } from './chat/token-login/token-login.component';
import {
  NominatorAiFeedbackDialogComponent
} from "@app/shared/components/nominator-ai-feedback-dialog/nominator-ai-feedback-dialog.component";
import {
  NominationSimilarListComponent
} from "@app/nomination-management/components/list/similar/nomination-similar-list.component";
import { FeedbackComponent } from './shared/components/feedback/feedback.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  imports: [
    BrowserModule,
    DisqusModule.forRoot('http-localhost-4200-webpack-dev-server'),
    BrowserAnimationsModule,
    // router
    AppRoutingModule,
    // design
    FormsModule,
    ReactiveFormsModule,
    // material
    AppMaterialModule,
    // notifications
    ToastrModule.forRoot(),
    // translations
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // http
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    // angular-firebase
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    MatNativeDateModule,
    TimeagoModule.forRoot(),
    MatDatepickerModule,
  ],
  declarations: [
    ConfirmationDialogComponent,

    // shared
    AppInitializrComponent,
    AppSpinnerComponent,
    // user-authentication
    LoginComponent,
    LogoutComponent,
    // user-dashboard
    DashboardLayoutComponent,
    NavbarComponent,
    SidenavComponent,
    // nomination-management
    NominationOpenedListComponent,
    NominationComponent,
    NominationClosedListComponent,
    NominationVoteComponent,
    NominationListWrapperComponent,
    NominationStatusBadgeComponent,
    MaxInputLengthNotifierDirective,
    UserListComponent,
    PurchaseListComponent,
    RequestModificationDialogComponent,
    UserCreateComponent,
    PurchaseComponent,
    ConfirmPurchaseDeleteComponent,
    ChatComponent,
    MessageNumberComponent,
    TokenLoginComponent,
    NominatorAiFeedbackDialogComponent,
    NominationSimilarListComponent,
    FeedbackComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    StorageService,
    SettingsService,
    ChatService
  ],
  bootstrap: [AppInitializrComponent],
  entryComponents: [
    AppSpinnerComponent,
    ConfirmationDialogComponent,
    UserCreateComponent,
    RequestModificationDialogComponent,
    PurchaseComponent,
    ConfirmPurchaseDeleteComponent
  ]
})
export class AppModule {
}
