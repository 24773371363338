import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  @Input() similarNomination: any;

  public ratings = [
    { value: 0, emoji: '😢' },    // Very sad
    { value: 1, emoji: '😞' },   // Sad
    { value: 2, emoji: '😐' },   // Neutral
    { value: 3, emoji: '🙂' },   // Slightly happy
    { value: 4, emoji: '😊' },   // Happy
    { value: 5, emoji: '😁' }   // Very happy
  ];


  public selectRating(value: number) {
    this.similarNomination.feedback = value;
  }

}
